// Libs
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "emotion";

// Utilities
import getPageFromId from "../../../utilities/get-page-from-id";
import req from "../../../utilities/request-utility";
import getProfilePictureFromUserObject from "../../../utilities/get-profile-picture-from-user-object";
import { parseDatetime } from "../../../utilities/parse-date";

// Components
import InlineSpinner from "../../ui/InlineSpinner";
import ButtonRounded from "../../ui/ButtonRounded";
import StatusBox from "../../ui/StatusBox";
import { InformationOutlineIcon } from "mdi-react";

// Styles and config
import colors from "../../../style/colors";
import breakpoints from "../../../config/breakpoints";

function EquipmentBookingSingleHistoryTab({ match }) {
  const [history, setHistory] = useState([]);
  const [loadingHistory, setLoadingHistory] = useState([]);

  const { pages } = useSelector((state) => state.pages);
  const [page] = useState(getPageFromId(pages, match.params.pageId));

  function getEquipmentHistory() {
    setLoadingHistory(true);
    let limit = history.length > 2 ? 20 : 2;
    let offset = history.length;
    req()(
      `equipment-booking/${page.dataId}/equipment/${match.params.equipmentId}/booking-history?limit=${limit}&offset=${offset}`
    )
      .then(({ data }) => {
        setLoadingHistory(false);
        setHistory([...history, ...data]);
      })
      .catch(() => {
        setLoadingHistory(false);
      });
  }

  useEffect(() => {
    getEquipmentHistory();
  }, []);

  return (
    <div className={componentStyles()}>
      {history.map((historyEntry) => (
        <div className="booking-details">
          <div className="upper-section">
            {getProfilePictureFromUserObject(historyEntry.user, 38)}
            <div className="user-details small">
              <p className="name">{historyEntry.user.name}</p>
              <p className="meta">
                Booket fra {parseDatetime(historyEntry.bookingStartDate)} til {parseDatetime(historyEntry.bookingEndDate)}
              </p>
            </div>
          </div>
          <p className="comment-label">Kommentar ved bookning:</p>
          {historyEntry.bookingStartComment && <p className="comment-content">{historyEntry.bookingStartComment}</p>}
          {!historyEntry.bookingStartComment && (
            <p className="comment-content meta" style={{ opacity: 0.8 }}>
              <InformationOutlineIcon style={{ width: "1rem", height: "1rem", marginBottom: "-0.2rem" }} /> Ingen kommentar
            </p>
          )}

          <p className="comment-label" style={{ marginTop: "1rem" }}>
            Kommentar ved afsluttet bookning:
          </p>
          {(!historyEntry.endedBy || historyEntry.endedBy.id === historyEntry.user.id) && (
            <>
              {historyEntry.bookingEndComment && <p className="comment-content">{historyEntry.bookingEndComment}</p>}
              {!historyEntry.bookingEndComment && (
                <>
                  <p className="comment-content meta" style={{ opacity: 0.8 }}>
                    <InformationOutlineIcon style={{ width: "1rem", height: "1rem", marginBottom: "-0.2rem" }} /> Ingen
                    kommentar
                  </p>
                </>
              )}
            </>
          )}

          {historyEntry.endedBy && historyEntry.endedBy.id !== historyEntry.user.id && (
            <div className="nested booking-details">
              <div className="upper-section">
                {getProfilePictureFromUserObject(historyEntry.endedBy, 38)}
                <div className="user-details small">
                  <p className="name">{historyEntry.endedBy.name}</p>
                  <p className="meta">Booking afsluttet {parseDatetime(historyEntry.bookingEndDate)}</p>
                </div>
              </div>
              <p className="comment-label" style={{ marginTop: "1rem" }}>
                Kommentar:
              </p>
              {historyEntry.bookingEndComment && <p className="comment-content">{historyEntry.bookingEndComment}</p>}
              {!historyEntry.bookingEndComment && (
                <>
                  <p className="comment-content meta" style={{ opacity: 0.8 }}>
                    <InformationOutlineIcon style={{ width: "1rem", height: "1rem", marginBottom: "-0.2rem" }} /> Ingen
                    kommentar
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      ))}

      {history.length === 0 && (
        <StatusBox
          icon={<InformationOutlineIcon />}
          title="Der er ingenting at vise her endnu"
          content="Du kan blive den første til at booke udstyret"
        />
      )}

      {history.length > 0 && !loadingHistory && (
        <ButtonRounded onClick={getEquipmentHistory} style={{ margin: "2rem auto" }} secondary={true}>
          Indlæs mere historik
        </ButtonRounded>
      )}

      {history.length > 0 && loadingHistory && (
        <p className="meta" style={{ textAlign: "center", margin: "2rem 0" }}>
          <InlineSpinner style={{ display: "inline-block", margin: "0 0.25rem -0.6rem 0" }} size="14" /> Indlæser historik...
        </p>
      )}
    </div>
  );
}

const componentStyles = () => css`
  max-width: ${breakpoints.md}px;
  margin: 1rem auto 5rem auto;

  p.meta {
    color: var(--darkGrey);
  }

  .booking-details {
    border: 1px var(--midGrey) solid;
    background-color: var(--white);
    border-radius: 3px;
    padding: 0.85rem;
    margin-bottom: 0.5rem;

    .upper-section {
      display: flex;
      align-items: center;
      margin-bottom: 0.85rem;

      .user-details {
        padding-left: 0.5rem;
      }
    }

    &.nested {
      margin-top: 0.5rem;
      margin-bottom: 0rem;
    }

    .ended-by {
      display: flex;
      gap: 0.5rem;

      margin: 0.85rem;
    }

    .comment-label {
      margin-bottom: 0.15rem;
    }

    .comment-content {
      font-size: 0.95rem;
      color: var(--darkGrey);
      white-space: pre-wrap;
    }
  }
`;

export default EquipmentBookingSingleHistoryTab;
