import Page from "../../../ui/Page";
import TopBar from "../../../ui/TopBar";
import useCurrentPage from "../../../../hooks/useCurrentPage";
import colors from "../../../../style/colors";
import ScrollView from "../../../ui/ScrollView";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import useSWR from "swr";
import { swrFetcher } from "../../../../utilities/swr-fetcher";
import { css } from "emotion";
import { InformationOutlineIcon } from "mdi-react";
import formatDate from "./utilities/formatDate";
import StatusBox from "../../../ui/StatusBox";

function Table({ table, columns }) {
  return (
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => {
          return (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>;
              })}
            </tr>
          );
        })}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr data-test-id={`checked-in-user-${row.id}`} key={row.id}>
            {row.getAllCells().map((cell) => {
              return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
            })}
          </tr>
        ))}
        {table.getRowModel().rows.length === 0 && (
          <tr data-test-id={`no-checked-in-users`}>
            <td colSpan={columns.length}>
              <StatusBox
                icon={<InformationOutlineIcon />}
                title="Ingen brugere er tjekket ind"
                content="Vi fandt ikke nogen indtjekkede brugere lige nu"
              />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

function CurrentlyCheckedInUsers({ match }) {
  const page = useCurrentPage({ pageId: match.params.pageId });
  const { data, isValidating: loading } = useSWR(
    `check-in-modules/admin/${page.dataId}/currently-checked-in-users`,
    swrFetcher
  );

  const helper = createColumnHelper();

  const columns = [
    helper.accessor("user.name", { header: "Bruger" }),
    helper.accessor("checkInTimestamp", {
      header: "Indtjekning",
      cell: (cell) => formatDate(cell.getValue()),
    }),
    helper.accessor("company", { header: "Virksomhed" }),
    helper.accessor("comment", { header: "Kommentar" }),
  ];

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Page backgroundColor={colors.white}>
      <TopBar useDefaultBackButton={true} title={page.title} />
      <ScrollView className={componentStyle()}>
        {!data && loading && <p>Loading...</p>}
        {data && (
          <div className="table-wrapper">
            <Table table={table} columns={columns} />
          </div>
        )}
      </ScrollView>
    </Page>
  );
}

const componentStyle = () => css`
  table {
    max-width: 1200px;
    margin: 0 auto;
  }

  @media screen and (min-width: 1200px) {
    table {
      margin-top: 2rem;
      border: 1px var(--midGrey) solid;
      border-radius: 8px;
    }
  }

  .action-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0.65rem;
  }

  .table-wrapper {
    overflow: auto;
  }

  table {
    color: var(--black);
    width: 100%;
    border-collapse: separate; /* Important! Default is collapse */
    border-spacing: 0; /* Remove gaps between cells */
    overflow: hidden;
    border-top: 1px var(--midGrey) solid;
    margin-bottom: 1rem;
    user-select: text;

    thead {
      background-color: var(--lightGrey);
      padding-top: 0.5rem;

      th {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }

    // All cells
    td,
    th {
      border-bottom: 1px var(--midGrey) solid;
      text-align: left;
      padding: 0.65rem 1rem;
    }
  }
`;

export default CurrentlyCheckedInUsers;
