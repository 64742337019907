import dayjs from "dayjs";
import { useEffect, useState } from "react";
import AnimateHeight from "react-animate-height";
import { durations } from "../../../../../config/animations";
import { css } from "emotion";
import breakpoints from "../../../../../config/breakpoints";
import { ChevronDownIcon, EditIcon, InformationOutlineIcon, TrashCanOutlineIcon } from "mdi-react";
import styleTypes from "../../../../../config/styleTypes";
import ButtonRounded from "../../../../ui/ButtonRounded";
import ActionWrapper from "../../../../ui/ActionWrapper";
import { useDispatch, useSelector } from "react-redux";
import { addToast, showDialog, showModalPage } from "../../../../../actions/uiActions";
import EditCheckInModal from "../../../modals/EditCheckInModal";
import colors from "../../../../../style/colors";
import requestUtility from "../../../../../utilities/request-utility";

function CheckInHistoryEntry({ checkIn, refreshCheckIns }) {
  const dispatch = useDispatch();
  const { checkInModuleId } = useSelector((s) => s.checkIn);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  function handleEdit(checkInId) {
    dispatch(
      showModalPage({
        content: <EditCheckInModal checkInId={checkInId} refreshCheckIns={refreshCheckIns} />,
        pageStyle: { backgroundColor: colors.white },
      })
    );
  }
  function handleDelete(checkInId) {
    dispatch(
      showDialog({
        styleType: styleTypes.error,
        title: "Slet indtjekning",
        content: "Er du sikker på at du vil slette indtjekningen?",
        primaryActionTitle: "Ja, slet indtjekning",
        primaryAction: () => {
          requestUtility()
            .delete(`check-in-modules/${checkInModuleId}/check-ins/${checkInId}`)
            .then(() => {
              setIsDeleted(true);
            })
            .catch((err) => {
              dispatch(addToast({ template: "error" }));
            });
        },
      })
    );
  }

  return (
    <div
      className={`${componentStyle()} ${isExpanded ? "expanded" : "collapsed"}  ${isDeleted ? "deleted" : ""}`}
      data-test-id="check-in-history-entry"
    >
      <ActionWrapper className={"expand-toggle"} onClick={() => setIsExpanded(!isExpanded)}>
        <p>
          {dayjs(checkIn.checkInTimestamp, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm")} -{" "}
          {dayjs(checkIn.checkOutTimestamp, "YYYYMMDDHHmm").format("YYYY-MM-DD HH:mm")}
        </p>
        <ChevronDownIcon className="chevron-icon" />
      </ActionWrapper>

      <AnimateHeight height={isExpanded ? "auto" : 0} duration={durations.normal} animateOpacity={true}>
        <div className="content">
          {dayjs().subtract(7, "days").isBefore(dayjs(checkIn.checkInTimestamp, "YYYYMMDDHHmm")) ? (
            <div className="actions-wrapper">
              <ButtonRounded
                size="small"
                styleType={styleTypes.neutral}
                secondary={true}
                onClick={() => handleEdit(checkIn.id)}
                data-test-id={"check-in-history-entry__edit-button"}
              >
                <EditIcon /> Rediger
              </ButtonRounded>
              <ButtonRounded
                size="small"
                styleType={styleTypes.error}
                secondary={true}
                onClick={() => handleDelete(checkIn.id)}
                data-test-id={"check-in-history-entry__delete-button"}
              >
                <TrashCanOutlineIcon /> Slet
              </ButtonRounded>
            </div>
          ) : (
            <p className="meta">
              <InformationOutlineIcon /> Indtjekningen er ældre end 7 dage og kan derfor ikke redigeres
            </p>
          )}

          <p className="label">Kommentar</p>
          <p className="value">{checkIn.comment}</p>
          <p className="label">Virksomhed</p>
          <p className="value">{checkIn.company}</p>
        </div>
      </AnimateHeight>

      <div className="expanded-border-highlight" />
    </div>
  );
}

const componentStyle = () => css`
  display: block;
  max-width: ${breakpoints.md}px;
  margin: 0 auto;
  position: relative;

  &.deleted {
    max-height: 500px;
    overflow: hidden;
    animation: deleteHistoryEntry ${durations.normal}ms ease forwards;
  }

  @keyframes deleteHistoryEntry {
    0% {
      max-height: 350px;
      opacity: 1;
    }

    100% {
      max-height: 0px;
      opacity: 0;
    }
  }

  .expanded-border-highlight {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 3px;
    background-color: transparent;
    opacity: 0;
    transition: opacity ${durations.normal}ms ease;
    background-color: var(--primary-color);
  }

  &.expanded .expanded-border-highlight {
    opacity: 1;
  }

  @media screen and (min-width: ${breakpoints.md}px) {
    border-left: 1px var(--midGrey) solid;
    border-right: 1px var(--midGrey) solid;
  }

  &:first-of-type {
    margin-top: 1rem;
  }

  &:last-of-type {
    border-bottom: 1px var(--midGrey) solid;
  }

  &.expanded {
    svg.chevron-icon {
      transform: scaleY(-1);
    }
  }
  svg.chevron-icon {
    color: var(--darkGrey);
  }

  svg {
    transition: transform ${durations.normal}ms ease;
  }

  .expand-toggle {
    color: var(--black);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-top: 1px var(--midGrey) solid;
    border-radius: 0px;
  }

  .content {
    padding: 0rem 1rem 0 1rem;

    p.meta {
      color: var(--darkGrey);
      margin: 0.5rem 0 1.5rem 0;

      svg {
        margin-bottom: -3px;
        width: 1rem;
        height: 1rem;
      }
    }

    .actions-wrapper {
      display: flex;
      gap: 0.65rem;
      margin-top: 0.35rem;
      margin-bottom: 1rem;

      svg {
        margin-right: 0.4rem;
        width: 1.15rem;
        height: 1.15rem;
      }
    }

    p.label {
      color: var(--darkGrey);
      font-size: 0.9rem;
      margin-bottom: 0rem;
    }

    p.value {
      color: var(--black);
      font-size: 1rem;
      padding-bottom: 1.25rem;
      white-space: pre-wrap;
    }
  }
`;

export default CheckInHistoryEntry;
