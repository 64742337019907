import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { css } from "emotion";
import { bindActionCreators } from "redux";
import { durations } from "../../config/animations";
import breakpoints from "../../config/breakpoints";
import { hideDialog, showDialog } from "../../actions/uiActions";
import convertNoneMarkdownLinksToMarkdown from "../../utilities/convertNoneMarkdownLinksToMarkdown";

// Components
import ActionWrapper from "./ActionWrapper";
import ImageCarousel from "./ImageCarousel";
import PostComments from "./PostComments";
import PostLikes from "./PostLikes";
import Slider from "./Slider";
import Notification from "./Notification";
import ContextMenuButton from "./ContextMenuButton";
import Author from "./Author";

// Style
import colors from "../../style/colors";

// Icons
import {
  ClockOutlineIcon,
  OpenInNewIcon,
  CheckIcon,
  PlusIcon,
  AccountCircleIcon,
  PinOutlineIcon,
  AccountGroupIcon,
} from "mdi-react";

// Utilities
import getProfilePicture from "../../utilities/get-profile-picture-from-user-object";
import { parse, format } from "date-fns";
import markdownParser from "../../utilities/markdown-parser";
import RegistrationPosts from "../registration/RegistrationPosts";
import FileViewer from "./FileViewer";
import LikeButton from "./LikeButton";
import CommentButton from "./CommentButton";
import AnimateHeight from "react-animate-height";

import VideoPlayer from "./VideoPlayer";
import Translator from "./Translator";
import ReadAloud from "./ReadAloud";
import { extractLinksFromMarkdown } from "../../utilities/extract-links-from-markdown";
import PostLink from "./PostLink";

class Post extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commentsVisible: false,
      likesVisible: false,
      contentMarkDown: "",
      linksFromContent: extractLinksFromMarkdown(props.content),
      isExpanded: false,
      animateReadStatusIn: false,
    };
  }

  componentDidMount() {
    this.setState({ content: this.props.content });
    this.fixNonMarkdownLinks();
    this.setState({ isExpanded: this.props.isExpanded });

    /** If the post is already read, the read status will appear with the post. If the post is not read
     * the read-status will animate in after the slider has dissappeared
     */
    if (!this.props.read) {
      this.setState({ animateReadStatusIn: true });
    }
  }

  fixNonMarkdownLinks = () => {
    let { content, enableMarkdownContent } = this.props;

    // If content isn't markdown don't parse links OR If no content is present then don't parse it... duh :-)
    if (!enableMarkdownContent || !content) return;

    let convertedContent = convertNoneMarkdownLinksToMarkdown(content);

    this.setState({ contentMarkDown: convertedContent });
  };

  getLikeText = (liked, likes, likeCount) => {
    /**
     * Returns the "N synes godt om"
     * or "Du og N andre"
     * etc.
     */
    let {
      lang,
      // Allows you to change "You like this" into ie. "You think this is useful"
      likeTextIdentifiers = {
        youLikeThis: "youLikeThis",
        youAndOneOtherLikeThis: "youAnd__placeholder__other",
        youAndMultipleOthersLikeThis: "youAnd__placeholder__others",
        oneOrManyOtherLikeThis: "__placeholder__likeThis",
      },
    } = this.props;

    // If likes is not included in the current data, then a likeCount is provided instead
    let localLikeCount;
    if (likeCount && typeof likeCount === "number") {
      localLikeCount = likeCount;
    } else {
      localLikeCount = likes.length;
    }

    // User liked post and no others have
    if (liked && localLikeCount === 1) {
      return lang[likeTextIdentifiers.youLikeThis];

      // User liked post and one other
    } else if (liked && localLikeCount === 2) {
      return lang[likeTextIdentifiers.youAndOneOtherLikeThis].replace("{{placeholder}}", localLikeCount - 1);

      // Users liked post and several others
    } else if (liked && localLikeCount > 2) {
      return lang[likeTextIdentifiers.youAndMultipleOthersLikeThis].replace("{{placeholder}}", localLikeCount - 1);

      // Post is not liked by user
    } else {
      return lang[likeTextIdentifiers.oneOrManyOtherLikeThis].replace("{{placeholder}}", localLikeCount);
    }
  };

  handleCommentDeletion = (commentId) => {
    let newComments = this.props.comments.filter((comment) => comment.id !== commentId);
    this.props.handleCommentUpdate(this.props.id, newComments);
  };

  getTitle = () => {
    const {
      title,
      titleStyle,
      showContextMenuToggle,
      contextMenuToggleCallback,
      isPinned = false,
      noTargetGroup = true,
      primaryColor,
      handleTargetGroupClick = () => {},
    } = this.props;
    return (
      <div className="headline-container">
        <h1 style={titleStyle} data-test-id="post__title">
          {title}
        </h1>

        <div className="headline-actions-container">
          {/* Pin */}
          {isPinned && (
            <div className="pin">
              <PinOutlineIcon />
            </div>
          )}

          {!noTargetGroup && (
            <ActionWrapper className="target-group-btn" onClick={handleTargetGroupClick}>
              <AccountGroupIcon style={{ margin: "-0.65rem -0.35rem 0 0", color: primaryColor }} />
            </ActionWrapper>
          )}

          {/* Context menu toggle for posts with a title (news posts) */}
          {showContextMenuToggle && (
            <ContextMenuButton
              onClick={contextMenuToggleCallback}
              data-test-id="post-context-menu-toggle"
              style={{ margin: "-0.35rem -0.35rem 0 0" }}
            />
          )}
        </div>
      </div>
    );
  };

  handleLinkClick = (link) => {
    const { lang, showDialog } = this.props;

    function action() {
      // If link starts with "www" it will be replaced with "http://www"
      // We will let it be up to the externla page to upgrade to https if it supports it
      link = link.replace(/^www/g, "http://www");
      window.open(link, "_blank");
    }

    // Extract the domain from the link
    const domain = new URL(link).hostname;

    if (this.props.appConfig.trustedDomains?.includes(domain)) {
      action();
      return;
    }

    let dialog = {
      title: lang.warningOpeningLink,
      content: lang.youAreOpening__placeholder__.replace(/{{placeholder}}/gi, link),
      styleType: "neutral",
      primaryActionTitle: lang.open__placeholder__.replace(/{{placeholder}}/gi, link),
      secondaryActionTitle: lang.cancel,
      primaryAction: action,
    };

    showDialog(dialog);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.content !== prevProps.content) this.fixNonMarkdownLinks();
    if (this.props.isExpanded !== prevProps.isExpanded) this.setState({ isExpanded: this.props.isExpanded });
  }

  render() {
    let {
      author,
      approvedBy,
      hideAuthorInApp = false,
      onAuthorClick,
      comments,
      commentCount,
      className,
      content,
      isTranslated,
      isTranslating,
      isPlayingAudio,
      isLoadingAudio,
      enableTranslation,
      enableReadAloud,
      translateContent,
      readContent,
      sliderRef,
      contextMenuToggleCallback,
      date,
      disableSwipe,
      documentOwner,
      documents = [],
      animateOut,
      id,
      images,
      liked,
      likeCount,
      likes,
      links,
      onLike,
      onCommentLike,
      onSwipeEnd,
      points,
      primaryColor,
      appConfig,
      read,
      readDate,
      readActionLangOverwrite,
      showContextMenuToggle,
      style,
      swipeCompleted,
      title,
      user,
      video,
      videoEmbed,
      componentContent,
      enableMarkdownContent = false,
      onCommentShow = null,
      onCommentHide = null,
      showNotifications = false,
      isPinned = false,
      // Comments
      loadingComments,
      submittingComment,
      onCommentContextMenu,
      commentsContainerRef,
      // Likes
      hideLikes = false,
      hideLikeButton,
      submittingLike,
      submittingLikedOnCommentId,
      isCommentLiked,
      lang,
      // config
      disableInteractionsPreview = false,
      disableLikesAndComments = false,
      allowLikesAndComments = true,
      // expand/collapse mode
      useExpandCollapseLayout = false,
      expandCollapseToggle = null,
    } = this.props;

    let { commentsVisible, likesVisible, linksFromContent, contentMarkDown } = this.state;

    return (
      <div
        className={`
        ${componentStyles(primaryColor, this.props, this.state.isExpanded)}
        ${animateOut ? "animate-out" : ""} 
        ${this.state.isExpanded ? "expanded" : "collapsed"} 
        ${className ? className : ""}
        ${disableInteractionsPreview ? "disable-interactions" : ""}
        `}
        style={style}
        data-test-id={this.props["data-test-id"] || ""}
      >
        {useExpandCollapseLayout === true && (
          <ActionWrapper
            className="expand-collapse-toggle"
            onClick={() => this.setState({ isExpanded: !this.state.isExpanded })}
            style={{ borderRadius: "2px" }}
          >
            {expandCollapseToggle}
            <div style={{ display: "flex", alignItems: "center" }}>
              {((!disableSwipe && !read) || showNotifications) && <Notification notifications={1} />}
              <PlusIcon className="expanded-indicator-icon" />
            </div>
          </ActionWrapper>
        )}
        <AnimateHeight
          height={this.state.isExpanded || useExpandCollapseLayout === false ? "auto" : 0}
          duration={durations.normal}
          animateOpacity={true}
        >
          {/* Title */}
          {title && this.getTitle()}

          {/* Meta data - author and date */}
          {((author && author.id) || date) && (
            <div className={`meta-information ${disableInteractionsPreview ? "disable-interactions" : ""}`}>
              <div className="left-side-wrapper">
                {!useExpandCollapseLayout && !hideAuthorInApp && author && author.id && date && (
                  <Author user={author} onClick={onAuthorClick} subTitle={date} />
                )}

                {!useExpandCollapseLayout && hideAuthorInApp && date && (
                  <div className="information-item author">
                    {getProfilePicture({ profilePicture: appConfig.icon }, 34, { marginRight: "0.5rem" })}
                    <div className="author-and-date-wrapper">
                      <p className="author">{appConfig.title}</p>
                      <p className="date">{date}</p>
                    </div>
                  </div>
                )}

                {!useExpandCollapseLayout && (!author || !author.id) && date && !hideAuthorInApp && (
                  <div className="information-item">
                    <ClockOutlineIcon />
                    <p>{date}</p>
                  </div>
                )}
              </div>

              {/* <div className="right-side-wrapper"> */}
              {/* Pin without title */}
              {isPinned && !title && (
                <div className="pin">
                  <PinOutlineIcon />
                </div>
              )}

              {/* Context menu toggle for posts without a title (social posts) */}
              {showContextMenuToggle && !title && (
                <ContextMenuButton
                  onClick={contextMenuToggleCallback}
                  data-test-id="post-context-menu-toggle"
                  style={{ margin: "-0.35rem -0.35rem 0 0" }}
                />
              )}
              {/* </div> */}
            </div>
          )}

          {approvedBy && documentOwner && (
            <div className="document-owner">
              <AccountCircleIcon color={colors.darkGrey} />
              <p>
                Godkendt af: ${approvedBy}. Dokumentejer: ${documentOwner}
              </p>
            </div>
          )}

          {video && <VideoPlayer baseURL={video.baseURL} video={video.video} />}

          {videoEmbed && (
            <iframe
              title={title}
              allow="accelerometer; encrypted-media; gyroscope;"
              allowFullScreen
              src={videoEmbed}
            ></iframe>
          )}

          {/* Image carousel */}
          {images && images.length > 0 && (
            <ImageCarousel disableLightbox={false} spacingBottom={spacingBottom} boxPadding={boxPadding} images={images} />
          )}

          {/* Component content*/}
          {componentContent && <div>{componentContent}</div>}

          {/* Content */}
          {content && !enableMarkdownContent && (
            <p data-test-id="post__content" className="content">
              {content}
            </p>
          )}

          {content && enableMarkdownContent === true && (
            <div
              data-test-id="post__content"
              className="content-container"
              onClick={(e) => {
                e.preventDefault();
                e.persist();
                if (e.target.tagName === "A") {
                  this.handleLinkClick(e.target.getAttribute("href"));
                }
              }}
              dangerouslySetInnerHTML={markdownParser(contentMarkDown)}
            />
          )}

          {(enableTranslation || enableReadAloud) && (
            <div className="translate-and-read-wrapper">
              {enableTranslation && (
                <Translator isTranslating={isTranslating} isTranslated={isTranslated} onTranslate={translateContent} />
              )}

              {enableReadAloud && (
                <ReadAloud isLoadingAudio={isLoadingAudio} isPlayingAudio={isPlayingAudio} onReadContent={readContent} />
              )}
            </div>
          )}

          {/* Documents */}
          {!documents || documents.length === 0 ? null : (
            <div className="attachments-container">
              {documents.map((document) => (
                <FileViewer
                  key={`file-viewer-compontent${document.file}`}
                  href={`${document.baseURL || ""}${document.file}`}
                  title={document.title}
                />
              ))}
            </div>
          )}

          {/* External links */}
          {!links || links.length === 0 ? null : (
            <div className="attachments-container">
              {links.map((link, linkKey) => (
                <a className="attachment" key={linkKey} href={link.URL} target="_blank" rel="noopener noreferrer">
                  <OpenInNewIcon style={{ fill: this.props.primaryColor }} />
                  <p>{link.title}</p>
                </a>
              ))}
            </div>
          )}

          {linksFromContent.length > 0 && (
            <div className="links-container-2">
              {linksFromContent.map((link) => (
                <PostLink
                  key={`link-key-${link.url + link.label}`}
                  url={link.url}
                  label={link.label}
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleLinkClick(link.url);
                  }}
                />
              ))}
            </div>
          )}

          {/* Swipe */}
          {!disableSwipe && !read && (
            <Slider
              sliderRef={sliderRef}
              style={{ marginBottom: "0.5rem" }}
              swipeCompleted={swipeCompleted}
              onSwipeEnd={onSwipeEnd}
              points={points}
              readActionLangOverwrite={readActionLangOverwrite}
            />
          )}

          {/* Read post */}
          {!disableSwipe && read && (
            <p
              className={`read-status meta ${this.state.animateReadStatusIn ? "animate-in" : ""}`}
              style={{ marginBottom: "0.5rem", color: colors.darkGrey }}
            >
              <CheckIcon style={{ width: "1rem", height: "1rem", marginBottom: "-3px" }} />{" "}
              {readActionLangOverwrite ? lang[readActionLangOverwrite] : lang.readAndUnderstood}{" "}
              {format(parse(readDate, "yyyyMMddHHmmss", 0), "dd/MM-yyyy HH:mm:ss")}
            </p>
          )}

          {/* Post actions (Likes, comments, approve, etc.) */}

          {allowLikesAndComments && disableLikesAndComments !== true && (likes || likeCount || comments || commentCount) && (
            <div className="post-actions">
              {/* Likes */}
              {!hideLikes && (likes || likeCount) && hideLikeButton !== true && (
                <LikeButton
                  liked={liked}
                  submittingLike={submittingLike}
                  onLike={onLike}
                  likesVisible={likesVisible}
                  getLikeText={() => this.getLikeText(liked, likes, likeCount)}
                  showComments={() => {
                    if (this.props.requestPostRefresh) this.props.requestPostRefresh();
                    this.setState({
                      commentsVisible: false,
                      likesVisible: !likesVisible,
                    });
                  }}
                />
              )}

              {!hideLikes && hideLikeButton && (
                <p className="meta" style={{ color: colors.darkGrey }}>
                  {this.getLikeText(liked, likes, likeCount)}
                </p>
              )}

              {/* Comments */}
              {(comments || typeof commentCount === "number") && (
                <CommentButton
                  commentsVisible={this.state.commentsVisible}
                  commentCount={commentCount}
                  comments={comments}
                  onClick={() => {
                    if (this.state.commentsVisible && onCommentHide) {
                      onCommentHide();
                    } else if (!this.state.commentsVisible && onCommentShow) {
                      onCommentShow();
                    }

                    this.setState({
                      commentsVisible: !commentsVisible,
                      likesVisible: false,
                    });
                  }}
                />
              )}
            </div>
          )}
        </AnimateHeight>

        {/* Likes */}
        <AnimateHeight
          id="comment-animation-wrapper"
          height={
            // This logic is a bit convoluted:
            // likes && likesVisible is sort of the normal logic. This means that if  there are likes and they are visible -> show the likes
            // (useExpandCollapseLayout === false || this.state.isExpanded === true) means that if there are likes and they are visible
            // AND we are either not using the expandCollapseLayout (previously the only option) OR the post is expanded -> show the likes
            likes && likesVisible && (useExpandCollapseLayout === false || this.state.isExpanded === true) ? "auto" : 0
          }
          duration={durations.normal}
          animateOpacity={true}
        >
          <div data-test-id="post-likes-container">
            <PostLikes likes={likes} likeCount={likeCount} boxPadding={boxPadding} />
          </div>
        </AnimateHeight>

        {/* Comments */}
        <AnimateHeight
          id="comment-animation-wrapper"
          height={comments && commentsVisible ? "auto" : 0}
          duration={durations.normal}
          animateOpacity={true}
        >
          <PostComments
            postId={id}
            submittingComment={submittingComment}
            onComment={this.props.onComment}
            isCommentLiked={isCommentLiked}
            submittingLikedOnCommentId={submittingLikedOnCommentId}
            onCommentLike={onCommentLike}
            comments={comments}
            loadingComments={loadingComments}
            boxPadding={boxPadding}
            user={user}
            onCommentContextMenu={onCommentContextMenu}
            forwardRef={commentsContainerRef}
          />
        </AnimateHeight>
      </div>
    );
  }
}

const spacingBottom = "0.75";
const boxPadding = "0.65";

const componentStyles = (primaryColor, props, isExpanded) => css`
  background-color: var(--white);
  margin-bottom: ${props.useExpandCollapseLayout === true ? "-1px" : "1rem"};
  border-top: 1px var(--midGrey) solid;
  border-bottom: 1px var(--midGrey) solid;
  white-space: initial;
  padding: ${boxPadding}rem ${boxPadding}rem 0 ${boxPadding}rem;
  overflow: hidden;
  max-height: 100000px;
  position: relative;
  overflow: visible;
  transition: max-height ${durations.slow}ms ease, border-width ${durations.slow}ms ease, padding ${durations.slow}ms ease,
    margin ${durations.slow}ms ease, opacity ${durations.slow * 1.5}ms ease, transform ${durations.slow}ms ease;

  @media screen and (min-width: ${breakpoints.md}px) {
    margin: 0 auto ${props.useExpandCollapseLayout === true ? "-1px" : "1rem"} auto;
    border: 1px var(--midGrey) solid;
    border-radius: 3px;
    max-width: ${breakpoints.md}px;
  }

  /* Class added when post is deleted */
  &.animate-out {
    max-height: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    border-width: 0px;
    opacity: 0;
    transform: scale(0.25);
    transform-origin: center center;
  }

  /* Class added if comments, likes or swipe shouldn't be interactive */
  &.disable-interactions {
    touch-action: none;
    pointer-events: none;
  }

  /* This is a class on the image carrousel */
  .image-carousel {
    touch-action: auto;
    pointer-events: auto;
  }

  /* Expand  collapse */
  &.collapsed {
    transition: margin ${durations.normal}ms ease;
  }
  &.expanded {
    margin: 0.5rem auto;
    transition: margin ${durations.normal}ms ease;

    &:first-of-type {
      margin-top: 0;
    }

    .expand-collapse-toggle svg.expanded-indicator-icon {
      transform: rotate(-45deg);
      fill: ${primaryColor};
    }
  }

  .expand-collapse-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + ${boxPadding * 2}rem);
    margin: -${boxPadding}rem -${boxPadding}rem 0 -${boxPadding}rem;
    padding: ${boxPadding}rem;
    border-bottom: ${props.useExpandCollapseLayout === true && isExpanded === true ? `1px solid var(--midGrey)` : ""};

    svg.expanded-indicator-icon {
      margin-left: 0.5rem;
      transition: transform ${durations.normal}ms ease;
      transform: rotate(0deg);
      color: var(--darkGrey);
    }
  }

  .headline-container {
    display: flex;
    justify-content: space-between;
    .headline-actions-container {
      display: flex;
    }
  }
  /* Header */
  h1 {
    margin-bottom: ${spacingBottom}rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: ${primaryColor};
    font-weight: 700;
  }

  .pin {
    margin-left: auto;
    margin-right: 0.35rem;
    transform: rotate(45deg);

    svg {
      color: ${primaryColor};
    }
  }

  .target-group-btn {
    &:active,
    &:focus {
      background-color: transparent;
    }
    svg {
      width: 36px;
      height: 36px;
      padding: calc(36px / 5);
      border-radius: 50%;
      color: var(--darkGrey);
      transition: background-color ${durations.normal}ms ease;
      flex-shrink: 0;

      &:active,
      &:hover {
        color: var(--black);
        background-color: var(--lightGrey);
        transition: background-color ${durations.ultraFast}ms ease;
        cursor: pointer;
      }
    }
  }

  .contextMenuToggle {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: -0.3rem;
    flex-shrink: 0;
    color: var(--black);
  }

  /* Video tag */
  video {
    background-color: var(--black);
    width: 100%;
    border-radius: 3px;
    border: 1px var(--lightGrey) solid;
    margin-bottom: 1rem;
    max-height: 50vw;

    &:focus {
      outline: 0;
    }
  }

  /* Youtube embed tag */
  iframe {
    background-color: var(--black);
    width: 100%;
    height: 350px;
    border-radius: 3px;
    border: 1px var(--lightGrey) solid;
    margin-bottom: 1rem;

    &:focus {
      outline: 0;
    }
  }

  /* Meta */
  .meta-information {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: ${spacingBottom * 1}rem;

    & > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .information-item {
        display: flex;
        align-items: center;
        margin-right: 1rem;
        margin-bottom: ${spacingBottom * 0.5}rem;

        .author-and-date-wrapper {
          text-align: left;

          p.author {
            color: var(--trueBlack);
            margin: -1px 0 0.15rem 0;
            font-size: 0.9rem;
          }
          p.date {
            color: ${colors.midDarkGrey};
            font-size: 0.8rem;
          }
        }

        & > div {
          margin-bottom: 0px !important;
        }

        svg {
          width: 1rem;
          height: 1rem;
          color: var(--darkGrey);
          margin-right: 0.5rem;
          flex-shrink: 0;
        }

        p {
          font-size: 0.9rem;
          color: var(--darkGrey);
        }
      }
    }
  }

  .document-owner {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-bottom: ${spacingBottom * 0.5}rem;

    svg {
      margin-right: 0.35rem;
    }

    p {
      font-size: 0.9rem;
      color: var(--darkGrey);
    }
  }

  /* Content */
  .content {
    margin-bottom: ${spacingBottom * 2}rem;
    line-height: 1.3;
    color: var(--black);
    white-space: pre-wrap;

    a {
      color: ${primaryColor};
      text-decoration: underline;
    }
  }

  .content-container {
    margin-bottom: ${spacingBottom * 2}rem;
    line-height: 1.5;
    overflow-wrap: break-word;
    color: var(--black);
    user-select: text;

    *::selection {
      background: ${primaryColor};
      color: var(--white);
      border-radius: 3px;
    }

    a {
      color: ${primaryColor};
      text-decoration: underline;
    }

    p {
      white-space: break-spaces;
    }

    p,
    ul,
    li {
      margin-bottom: 0.5rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      margin-top: 1.5rem;
      margin-bottom: 0.65rem;
      &:first-child {
        margin-top: 0rem;
      }
    }

    ul {
      list-style-type: disc;
      margin-left: 1.5rem;
    }

    ol {
      margin-left: 1.5rem;
    }

    h1 {
      font-size: 1.15rem;
      color: ${primaryColor};
      font-weight: 700;
    }
    h2 {
      font-size: 1.1rem;
      color: ${primaryColor};
      font-weight: 700;
    }
    h3 {
      font-size: 1.1rem;
      color: ${primaryColor};
      font-weight: 700;
    }
    h4 {
      font-size: 1rem;
      color: ${primaryColor};
      font-weight: 700;
    }
  }

  /* Documents amd links */
  .attachments-container {
    border: 1px var(--midGrey) solid;
    border-radius: 3px;
    margin-bottom: ${spacingBottom}rem;
    overflow: hidden;

    .attachment {
      text-decoration: none;
      display: block;
      color: var(--darkGrey);
      border-bottom: 1px var(--midGrey) solid;
      padding: 0.5rem;
      position: relative;
      transition: background-color 180ms ease;

      &:last-child {
        border-bottom: 0px;
      }

      &:active {
        background-color: var(--lightGrey);
        transition: background-color 180ms ease;
      }

      p {
        padding-left: 1.75rem;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 0.6rem;
        transform: translateY(-50%);
        width: 1rem;
        height: 1rem;
        vertical-align: middle;
        fill: var(--darkGrey);
      }
    }
  }

  .links-container-2 {
    border-radius: 3px;
    border-bottom: 1px solid var(--midGrey);

    overflow: clip;

    margin-bottom: ${spacingBottom}rem;
  }

  /* Links */
  .links-container {
    border: 1px solid var(--midGrey);
    border-radius: 3px;
    margin-bottom: 1rem;

    button {
      border-bottom: 1px solid var(--midGrey);
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      &:last-child {
        border-bottom: none;
      }
    }

    .link-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;

      .link-image-wrapper {
        border-radius: 3px;
        display: flex;
        justify-content: center;
        width: 40px;
        height: 40px;
        align-items: center;
        margin-right: 0.5rem;
      }

      > div {
        display: flex;
        align-items: center;
      }

      img,
      .image-stand-in {
        width: 40px;
        min-width: 40px;
        height: 40px;
        margin-right: 0.5rem;
        border-radius: 3px;
        flex-shrink: 0;
      }

      p {
        font-size: 0.9rem;
        max-height: 38px; // Sort of magic number here!
        overflow: hidden;
      }

      .image-stand-in {
        position: relative;
        background-color: var(--lightGrey);
        border-radius: 3px;

        svg {
          top: calc(50%);
          left: calc(50%);
          transform: translate3d(-50%, -50%, 0);
          position: absolute;
        }
      }

      img {
        object-fit: cover;
      }

      svg.external-link-icon {
        flex-shrink: 0;
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
      }
    }
    :last-child {
      .link-container {
        border-bottom: none;
      }
    }
  }

  p.read-status {
    &.animate-in {
      line-height: 42px;
      opacity: 0;
      animation-delay: 200ms;
      animation: read-status-enter 600ms ease forwards;
    }

    @keyframes read-status-enter {
      0% {
        line-height: 42px;
        opacity: 0;
        transform: translateX(30px) scale(0.8);
      }
      100% {
        line-height: 10px;
        opacity: 1;
        transform: translateX(0px) scale(1);
      }
    }
  }

  /* Post-actions (like, comments, approve etc. */
  .post-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: ${boxPadding}rem;
  }

  .translate-and-read-wrapper {
    display: flex;
    margin-top: -0.5rem;
    margin-bottom: 1rem;

    .button {
      color: var(--darkGrey);
      display: inline-block;
      font-size: 0.875rem;
      padding: 0.15rem 0.25rem;
      margin-left: -0.25rem;
      margin-right: 0.65rem;

      svg {
        margin: 0 0.3rem -2px 0;
        width: 0.9rem;
        height: 0.9rem;
        color: var(--darkGrey);
      }
    }
  }
`;

Post.propTypes = {
  /** Bool value which decides whether or not the contextmenu is visible */
  showContextMenuToggle: PropTypes.bool,
  /** Function to be executed when contextmenu, eg. you should pass an instance of reduxaction showcontextmenu to this */
  contextMenuToggleCallback: PropTypes.func,
  /** Main title of element */
  title: PropTypes.string,
  /** Array of images to be shown in the element */
  images: PropTypes.array,
  /** Creator of the post */
  author: PropTypes.object,
  /** Creation date of the post */
  date: PropTypes.string,
  /** Numerical value which tells how many points a swipe action gives */
  points: PropTypes.number,
  /** Text body of the element */
  // content: PropTypes.string,
  /** Related documents to the post */
  documents: PropTypes.array,
  /** Related links to the post */
  links: PropTypes.array,
  /** Bool value which defines if post should be swiped to confirm read-status */
  disableSwipe: PropTypes.bool,
  /** Bool value which defines if post is read or not */
  read: PropTypes.bool,
  /** Array of objects of users that have liked the post */
  likes: PropTypes.array,
  /** Array of comments related to post */
  comments: PropTypes.array,
  /** Bool value which decides if likes are visible */
  likesVisible: PropTypes.bool,
  /** Bool value which tells if specific user has liked post*/
  liked: PropTypes.bool,
  /** Function to be executed when post is "swiped" */
  onSwipeEnd: PropTypes.func,
  /** Bool value which decides if comments are visible */
  commentsVisible: PropTypes.bool,
  /** Bool value which decides whether or not the post viewing a preview and thereby a non-interactive edition of the post */
  disableInteractionsPreview: PropTypes.bool,
};

RegistrationPosts.defaultProps = {
  hideLikeButton: false,
};

const mapStateToProps = (state) => ({
  appConfig: state.appConfig,
  primaryColor: state.appConfig.primaryColor,
  lang: state.language.language,
});

const mapDispatchToProps = (dispatch) => ({
  showDialog: bindActionCreators(showDialog, dispatch),
  hideDialog: bindActionCreators(hideDialog, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Post);
