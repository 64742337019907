// Libs
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import store from "../../store";
import { css } from "emotion";

// Redux actions
import { addToast, showContextMenu, showModalPage, hideModalPage, showDialog } from "../../actions/uiActions";
import { renewPasswordHandled, signInFailureHandled } from "../../actions/authActions";

// components
import Page from "../ui/Page";
import ContextMenu from "../ui/ContextMenu";
import {
  CellphoneIphoneIcon,
  ErrorOutlineIcon,
  EmailOutlineIcon,
  CheckCircleOutlineIcon,
  LockQuestionIcon,
  MicrosoftWindowsIcon,
  MoreVertIcon,
  ToolboxIcon,
  WrenchIcon,
  QuestionMarkIcon,
} from "mdi-react";
import PhoneLogin from "./PhoneLogin";
import EmailLogin from "./EmailLogin";
import ForgotPassword from "./ForgotPassword";
import AdLogin from "./AdLogin";
import ToastHandler from "../ui/ToastHandler";
import ModalPage from "../ui/ModalPage";
import Dialog from "../ui/Dialog";
import InlineSpinner from "../ui/InlineSpinner";
import ButtonRounded from "../ui/ButtonRounded";

// Utilities
import getAppName from "../../utilities/get-app-name";
import req from "../../utilities/request-utility";
import saveStateToLocalStorage from "../../utilities/save-state-to-local-storage";
import { getToken } from "../../utilities/login-transfer-token-utils";
import nativeCommunicator from "../../utilities/native-communicator";

// Actions
import { HIDE_DIALOG, SIGN_IN_SUCCESS } from "../../actions/actionTypes";
import { getLanguage } from "../../actions/languageActions";
import { getPages } from "../../actions/pagesActions";

// Styles & Config
import loginTypes from "./config/loginTypes";
import colors from "../../style/colors";
import ActionWrapper from "../ui/ActionWrapper";
import DebugModal from "../debugModal/DebugModal";

/** Page that log's in users
 * Is registered as a route and therefore doesn't need any props.
 *
 * The implementation of email login is a bit cramped in. I initially though the implementation
 * would be leaner by reusing the main functionality and just changing the input element but it
 * turned out a bit weird, so for the next login type we add i would argue for seperate login
 * components: a PhoneLogin.jsx, EmailLogin.jsx, ADLogin.jsx, EmployeeNubmerLogin.jsx etc.
 *
 */

//  Hack to prevent ad-login to start before appConfig has been updated
let firstRenderManual = true;

// Prevents login transfer from running multiple times
let hasTriedLoginTransfer = false;

function LoginPage({ className = "" }) {
  const [currentLoginType, setCurrentLoginType] = useState("");
  const [currentLoginValue, setCurrentLoginValue] = useState(""); // used for prefilling "Forgot password" modal
  const [enableInput, setEnableInput] = useState(true);
  const dispatch = useDispatch();
  const appConfig = useSelector((s) => s.appConfig);
  const appConfigLoading = useSelector((s) => s.appConfig.loading);
  const { renewPasswordSuccess, signInError, signingIn } = useSelector((s) => s.auth);
  const [highlightForgotPasswordButton, setHightlightForgotPasswordButton] = useState(false);
  const userAgentIsFromNative = window.navigator.userAgent.match(/EkkoAppNativeWrapper/gi);

  // This effect makes sure that the app-config is refreshed before any redirects will happen
  useEffect(() => {
    if (firstRenderManual === false && appConfig.apiUrl !== "" && appConfig.loading === false) {
      getDefaultLoginType();
    }
    firstRenderManual = false;

    // eslint-disable-next-line
  }, [appConfig, appConfigLoading]);

  function getDefaultLoginType() {
    /**
     * Determines which login type to use as default. It will use the first in the array
     * of allowed login types if the first is regonized. Otherwise it will default to "phone".
     * The array is found in the app-config returned from the api
     *
     * Examples
     * ["email", "phone", "ad"] -> default will be "email"
     * ["phone", "email", "ad"] -> default will be "phone"
     * ["blablaLoremIpsum", "email", "phone", "ad"] -> default will be "email"
     *
     */

    if (hasTriedLoginTransfer === false) {
      hasTriedLoginTransfer = true; // Set app to already having tried transfer login
      setCurrentLoginType(loginTypes.transferLogin); // Set login mode to transfer login
      tryLoginTransfer(); // Try to actually perform the login
      return;
    }

    let { allowedLoginTypes } = appConfig;

    if (!allowedLoginTypes) allowedLoginTypes = [loginTypes.phone];

    switch (allowedLoginTypes[0]) {
      case loginTypes.phone:
        setCurrentLoginType(loginTypes.phone);
        break;
      case loginTypes.email:
        setCurrentLoginType(loginTypes.email);
        break;
      case loginTypes.ad:
        setCurrentLoginType(loginTypes.ad);
        break;
      default:
        setCurrentLoginType(loginTypes.phone);
    }
  }

  if (signInError) {
    dispatch(signInFailureHandled());
    dispatch(
      addToast({
        title: "Kunne ikke logge ind",
        content: "Dine logininformationer var ikke gyldige",
        icon: <ErrorOutlineIcon />,
        styleType: "error",
        duration: 20000,
      })
    );
    setHightlightForgotPasswordButton(true);
    // Reset so the animation can be applied again and again
    setTimeout(() => {
      setHightlightForgotPasswordButton(false);
    }, 3500);
  }

  // If everything goes right when resetting password
  if (renewPasswordSuccess) {
    dispatch(renewPasswordHandled());
    dispatch(
      addToast({
        title: "Dit kodeord er blevet nulstillet",
        content: "Du vil modtage en sms snarest med dit nye kodeord",
        icon: <CheckCircleOutlineIcon />,
        styleType: "success",
        duration: 20000,
      })
    );
  }

  function forgotPassword() {
    dispatch(
      showModalPage({
        title: "Forgot password",
        closeCallback: () => {
          setEnableInput(true);
          dispatch(hideModalPage());
        },
        content: <ForgotPassword prefillValue={currentLoginValue} />,
        useScrollView: false,
      })
    );
  }

  function showChangeLoginTypeMenu() {
    let actions = [];

    if (appConfig.allowedLoginTypes.includes(loginTypes.email) && currentLoginType !== loginTypes.email) {
      actions.push({
        icon: <EmailOutlineIcon />,
        title: "Login with email",
        callback: () => setCurrentLoginType(loginTypes.email),
      });
    }

    if (appConfig.allowedLoginTypes.includes(loginTypes.phone) && currentLoginType !== loginTypes.phone) {
      actions.push({
        icon: <CellphoneIphoneIcon />,
        title: "Login with phone",
        callback: () => setCurrentLoginType(loginTypes.phone),
      });
    }

    if (appConfig.allowedLoginTypes.includes(loginTypes.ad) && currentLoginType !== loginTypes.ad) {
      actions.push({
        icon: <MicrosoftWindowsIcon />,
        title: "Login with AD",
        callback: () => setCurrentLoginType(loginTypes.ad),
      });
    }

    actions.push({
      icon: <WrenchIcon />,
      title: "Developer info",
      callback: () => dispatch(showModalPage({ content: <DebugModal /> })),
    });

    dispatch(showContextMenu({ actions: actions, defaultActionTitle: "Cancel" }));
  }

  function tryLoginTransfer() {
    getToken()
      // If a token is found
      .then((token) => {
        req()
          .post(`/auth/sign-in-with-transfer-token/`, { token, appName: getAppName() })
          .then(({ data }) => {
            // Disables spinner
            setCurrentLoginType("");

            const queryStrings = new URLSearchParams(window.location.search);
            const skipTransferDialog = queryStrings.get("skip-transfer-dialog") == true; // Any truthy value will be considered true

            if (skipTransferDialog) {
              dispatch({
                type: SIGN_IN_SUCCESS,
                payload: {
                  token: data.token,
                  user: data.user,
                },
              });
              // Wait for next tick and trigger language and pages (when redux store is updated with auth-data)
              setTimeout(() => {
                dispatch(getLanguage());
                dispatch(getPages());
              }, 1);

              return;
            }

            dispatch(
              // Consider making a query-param to specify which type of dialog to show. This would be something along the lines of type="LOGIN_TRANSFER"
              // but for new users the dialog should probably either not be shown or have a different wording
              showDialog({
                allowClosing: false,
                title: "Velkommen til den nye app 👋",
                content: `Vi kan se du er logget ind i den gamle app som ${data.user.name}. Vil du fortsætte som ${data.user.name}?`,
                primaryActionTitle: `Ja, fortsæt som ${data.user.name}`,
                primaryAction: () => {
                  dispatch({
                    type: SIGN_IN_SUCCESS,
                    payload: {
                      token: data.token,
                      user: data.user,
                    },
                  });
                  // Wait for next tick and trigger language and pages (when redux store is updated with auth-data)
                  setTimeout(() => {
                    dispatch(getLanguage());
                    dispatch(getPages());
                  }, 1);
                },
                secondaryActionTitle: `Nej tak, log ind som en anden bruger`,
                secondaryAction: () => {
                  getDefaultLoginType();
                  dispatch({ type: HIDE_DIALOG });
                },
              })
            );

            // Save state (wait for eventloop to not get stale state)
            setTimeout(() => saveStateToLocalStorage(store.getState()), 0);
          })
          // If the sign in process doesn't work
          .catch(getDefaultLoginType);
      })
      // If a token isn't found
      .catch(getDefaultLoginType);
  }

  return (
    <Page className={`${componentStyle(appConfig)} ${className}`} style={{ justifyContent: "space-between" }}>
      <div className="login-container">
        <div className="app-logo" onClick={() => setHightlightForgotPasswordButton(!highlightForgotPasswordButton)}>
          <div className="loading-container" style={{ opacity: signingIn ? 1 : 0 }}>
            {signingIn && <InlineSpinner size={28} />}
          </div>
        </div>
        {!appConfig.appId && (
          <div className="text-container">
            <p className="pageNotFound-title-text">No app found</p>
            <p className="pageNotFound-text">
              You tried to access an app at <b>/{getAppName()}</b>, but unfortunately we did not find one with that name.
            </p>
            <p className="pageNotFound-text">
              Check that your spelling is correct. If everything seems fine then the app may have been deleted or moved.
            </p>

            {userAgentIsFromNative && (
              <ButtonRounded
                style={{ backgroundColor: "var(--nativeGreen)", width: "80%", maxWidth: "350px", fontSize: "0.9rem" }}
                onClick={() => nativeCommunicator.send("select-another-app", {})}
              >
                Select another app
              </ButtonRounded>
            )}
          </div>
        )}

        {appConfig.appId && (
          <>
            {/* Actual login part */}
            {currentLoginType === loginTypes.transferLogin && (
              <div>
                <InlineSpinner /> Trying to log you in...
              </div>
            )}

            {currentLoginType === loginTypes.phone && (
              <PhoneLogin sendCurrentLoginToParent={setCurrentLoginValue} enableInput={enableInput} />
            )}
            {currentLoginType === loginTypes.email && (
              <EmailLogin sendCurrentLoginToParent={setCurrentLoginValue} enableInput={enableInput} />
            )}
            {currentLoginType === loginTypes.ad && <AdLogin />}

            {currentLoginType !== "" && (
              <ActionWrapper
                className={`forgot-password ${highlightForgotPasswordButton ? "highlight" : ""}`}
                onClick={forgotPassword}
              >
                <LockQuestionIcon /> Forgot password?
              </ActionWrapper>
            )}
            <ActionWrapper className={`forgot-password`} onClick={showChangeLoginTypeMenu}>
              <MoreVertIcon /> More options
            </ActionWrapper>

            {/* Load frontpage image so its cached and ready for frontpage */}
            <img
              alt=""
              style={{ display: "none" }}
              src={`${appConfig.frontPageImage.baseURL}b_black,o_50,h_800,q_auto,f_auto/${appConfig.frontPageImage.image}`}
            />
          </>
        )}
      </div>

      <ModalPage />
      <ContextMenu />
      <Dialog />
      <ToastHandler />
      {/* <SecretDebugPanel /> */}
    </Page>
  );
}
const componentStyle = (appConfig) => css`
  .app-logo {
    width: 80px;
    height: 80px;
    flex-shrink: 0;
    border-radius: 18px;
    border: 1px var(--lightGrey) solid;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    background-image: url(${appConfig.appId ? appConfig.icon.baseURL + appConfig.icon.image : "https://res.cloudinary.com/toecho/image/upload/no-app-found.png"});
    background-color: rgba(255, 255, 255, 0.75);
    background-size: cover;
    background-position: center;
    margin: 0 auto 5vh auto;
    position: relative;
    overflow: hidden;

    .loading-container {
      transition: opacity 400ms ease;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(255, 255, 255, 0.75);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .login-container {
    margin: 0 auto;
    padding: 10vh 0;
    max-height: 60vh; // Numpad is 40vh so this prevents overlap
    overflow-y: auto;
    /* -webkit-overflow-scrolling: touch; */ /* DON'T ENABLE THIS: If this is enabled the NumPad will dissappear when rotating iOS devices.. */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  svg.more-options {
    position: absolute;
    top: 0.65rem;
    right: 0.65rem;
    border: 1px var(--midGrey) solid;
    color: ${appConfig.primaryColor};

    &:active {
      background-color: var(--midGrey);
      transition: background-color 80ms ease;
    }
  }

  button.forgot-password,
  button.toggle-input-types {
    flex-shrink: 0;
    display: block;
    font-size: 0.925rem;
    color: ${appConfig.primaryColor};
    padding: 0rem 1.45rem;
    border: 1px var(--midGrey) solid;
    border-radius: 1rem;
    height: 2rem;
    line-height: 1.5rem;
    outline: 0px;
    background-color: transparent;
    transition: background-color 200ms ease;
    margin-top: 2vh;
    &:active {
      background-color: var(--midGrey);
      transition: background-color 80ms ease;
    }
    svg {
      width: 1rem;
      height: 1rem;
      margin-bottom: -0.15rem;
      margin-right: 0.35rem;
    }

    &.toggle-input-types {
      position: absolute;
      top: 0;
      right: 15px;
    }

    &.highlight {
      animation: shakeButton 1.5s ease forwards, highlightButton 3s ease forwards;
      animation-delay: 300ms;

      @keyframes highlightButton {
        0% {
          background-color: transparent;
        }
        30% {
          background-color: var(--yellowLight);
        }
        60% {
          background-color: var(--yellowLight);
        }
        100% {
          background-color: transparent;
        }
      }
      @keyframes shakeButton {
        0% {
          transform: scale(1) rotate(-5deg);
        }
        10% {
          transform: scale(1) rotate(5deg);
        }
        20% {
          transform: scale(1) rotate(-5deg);
        }
        30% {
          transform: scale(1.15) rotate(5deg);
        }
        40% {
          transform: scale(1.15) rotate(-5deg);
        }
        50% {
          transform: scale(1.15) rotate(5deg);
        }
        60% {
          transform: scale(1.15) rotate(-5deg);
        }
        70% {
          transform: scale(1.15) rotate(5deg);
        }
        80% {
          transform: scale(1.15) rotate(-5deg);
        }
        90% {
          transform: scale(1) rotate(5deg);
        }
        100% {
          transform: scale(1) rotate(0deg);
        }
      }
    }
  }
  div.input {
    display: inline-block;
    padding: 0.75rem;
    margin: 0 auto;
    font-size: 1.3rem;
    letter-spacing: 2px;
    color: var(--black);
    white-space: nowrap;
    font-weight: 700;
    &.password .password-dots-container {
      display: inline-block;
      margin-left: 1rem;
    }
    &.phone {
      min-width: 257px; /* Also a magic number */
      min-height: 54px; /* Also a magic number */
      display: flex;
      align-items: center;

      svg {
        margin-top: 0px;
      }
    }
    &.email {
      height: 52px; /* Sry. Another magic number */
    }

    &.email input {
      padding: 0.5rem;
      border: 1px var(--midGrey) solid;
      appearance: none;
      border-radius: 2px;
      background-color: rgba(0, 0, 0, 0.03);
      margin-left: 0.5rem;
      font-size: 0.85rem;

      &:focus {
        outline: 0;
        border: 1px ${appConfig.primaryColor} solid;
      }
    }

    svg.login-type-icon {
      color: var(--darkGrey);
      vertical-align: middle;
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -5px;

      &.lock-icon {
        margin-top: -18px;
      }
    }
  }

  /* Phone cursor */
  .phone .phone-cursor {
    width: 16px;
    height: 3px;
    background-color: var(--midGrey);
    display: inline-block;
    vertical-align: text-bottom;
    align-self: flex-end;
    animation: cursorBlinkPhone 1s linear infinite;
  }

  @keyframes cursorBlinkPhone {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }

  /* Password dots */
  .password-dots-container {
    .password-dot {
      display: inline-block;
      width: 22px;
      height: 22px;
      margin-right: 0.4rem;
      border-radius: 50%;
      border: 1px var(--midGrey) solid;

      &.blink {
        animation: cursorBlinkPassword 1s linear infinite;
      }

      @keyframes cursorBlinkPassword {
        0% {
          border-width: 1px;
        }
        5% {
          border-width: 4px;
          border-color: ${appConfig.primaryColor};
        }
        53% {
          border-width: 4px;
          border-color: ${appConfig.primaryColor};
        }
        60% {
          border-width: 1px;
        }
      }

      &.filled {
        background-color: ${appConfig.primaryColor};
        border: 0px;
      }
    }
  }

  // Mini screens accomodations
  @media screen and (max-height: 570px) {
    .app-logo {
      width: 60px;
      height: 60px;
      margin-bottom: 1rem;
    }

    .login-container {
      padding-top: 3vh;
    }
  }
  // Very mini screens accomodations
  @media screen and (max-height: 350px) and (orientation: landscape) {
    .app-logo {
      width: 60px;
      height: 60px;
      position: absolute;
      top: 12px;
      left: 12px;
    }

    .login-container {
      padding-top: 1vh;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    max-width: 350px;
  }

  .pageNotFound-title-text {
    font-size: 1rem;
    font-weight: 500;
  }
  .pageNotFound-text {
    font-size: 0.8rem;
    text-align: center;
    color: var(--darkGrey);
  }
  .native-bak-button {
    background-color: "#2F7078";
  }
`;

export default LoginPage;
