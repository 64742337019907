import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ScrollView from "../../ui/ScrollView";
import colors from "../../../style/colors";
import CheckInForm from "../components/CheckInForm";
import { ContentSaveIcon } from "mdi-react";
import InlineSpinner from "../../ui/InlineSpinner";
import requestUtility from "../../../utilities/request-utility";
import { updateFormData } from "../../../actions/checkInActions";
import dayjs from "dayjs";
import { addToast, hideModalPage } from "../../../actions/uiActions";
import styleTypes from "../../../config/styleTypes";

function EditCheckInModal({ checkInId, refreshCheckIns }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { checkInModuleId, checkInForm } = useSelector((s) => s.checkIn);

  useEffect(() => {
    // Get formData:
    requestUtility()(`check-in-modules/${checkInModuleId}/check-ins/${checkInId}`).then(({ data }) => {
      const parsedCheckIn = dayjs(data.checkInTimestamp);
      const parsedCheckOut = dayjs(data.checkOutTimestamp);

      dispatch(updateFormData({ name: "checkInDate", value: parsedCheckIn.format("YYYY-MM-DD") }));
      dispatch(updateFormData({ name: "checkInHour", value: parsedCheckIn.format("HH") }));
      dispatch(updateFormData({ name: "checkInMinute", value: parsedCheckIn.format("mm") }));
      dispatch(updateFormData({ name: "checkOutDate", value: parsedCheckOut.format("YYYY-MM-DD") }));
      dispatch(updateFormData({ name: "checkOutHour", value: parsedCheckOut.format("HH") }));
      dispatch(updateFormData({ name: "checkOutMinute", value: parsedCheckOut.format("mm") }));
      dispatch(updateFormData({ name: "company", value: data?.company }));
      dispatch(updateFormData({ name: "comment", value: data?.comment }));

      setIsLoading(false);
    });
  }, []);

  function handleSubmit() {
    setIsSaving(true);
    const { formData } = checkInForm;

    // Prepare data for submit-format:
    const formattedFormData = {
      checkInTimestamp: formData.checkInDate.replace(/-/gi, "") + formData.checkInHour + formData.checkInMinute,
      checkOutTimestamp: formData.checkOutDate.replace(/-/gi, "") + formData.checkOutHour + formData.checkOutMinute,
      comment: formData.comment,
      company: formData.company,
    };

    requestUtility()
      .put(`check-in-modules/${checkInModuleId}/check-ins/${checkInId}`, formattedFormData)
      .then(() => {
        setIsLoading(false);
        refreshCheckIns();
        dispatch(hideModalPage());
      })
      .catch((err) => {
        setIsLoading(false);
        setIsSaving(false);
        dispatch(
          addToast({
            duration: 10000,
            title: "Noget gik galt",
            content: err?.response?.data?.message,
            styleType: styleTypes.error,
          })
        );
      });
  }

  if (isLoading) {
    return (
      <>
        <InlineSpinner size="24" style={{ marginTop: "2rem" }} />
        <p style={{ color: colors.darkGrey, textAlign: "center" }}>Indlæser data...</p>
      </>
    );
  }

  return (
    <ScrollView backgroundColor={colors.white}>
      <CheckInForm
        submitButtonContent={
          isSaving ? (
            <InlineSpinner size="20" color={colors.white} style={{ marginTop: "4px" }} />
          ) : (
            <>
              <ContentSaveIcon style={{ width: "1.1rem", height: "1.1rem", marginRight: "0.5rem" }} />
              Gem ændringer
            </>
          )
        }
        onSubmit={handleSubmit}
      />
    </ScrollView>
  );
}

export default EditCheckInModal;
