/**
 * These functions are used to filter the selection tabs (the left side panel).
 */
export const filterFunctions = {
  users: ({ words, data }) =>
    data.filter((d) => {
      if (words.length > 1) {
        let findCount = 0;
        words.forEach((word) => {
          let regex = new RegExp(word, "gi");
          let foundCustomGroup = false;

          if (d.name?.match(regex)) return findCount++;
          if (d.jobTitle?.match(regex)) return findCount++;
          if (d.userGroup?.title?.match(regex)) return findCount++;
          if (d.masterGroup?.title?.match(regex)) return findCount++;

          if (d.customGroups.length === 1 && d.customGroups[0].title.match(regex)) {
            return findCount++;
          } else {
            d.customGroups = d.customGroups?.sort((a, b) => {
              if (a.title?.match(regex)) {
                foundCustomGroup = true;
                return -1;
              }
              if (b.title?.match(regex)) {
                foundCustomGroup = true;
                return 1;
              }
              return 0;
            });
          }
          if (foundCustomGroup) return findCount++;
        });

        if (findCount === words.length) return true;
        return false;
      } else {
        let regex = new RegExp(words[0], "gi");
        let foundCustomGroup = false;

        if (d.name?.match(regex)) return true;
        if (d.jobTitle?.match(regex)) return true;
        if (d.userGroup?.title?.match(regex)) return true;
        if (d.masterGroup?.title?.match(regex)) return true;

        if (d.customGroups.length === 1 && d.customGroups[0].title.match(regex)) {
          return true;
        } else {
          d.customGroups = d.customGroups?.sort((a, b) => {
            if (a.title?.match(regex)) {
              foundCustomGroup = true;
              return -1;
            }
            if (b.title?.match(regex)) {
              foundCustomGroup = true;
              return 1;
            }
            return 0;
          });
        }

        if (foundCustomGroup) return true;
        return false;
      }
    }),
  userGroups: ({ words, data }) =>
    data.filter((d) => {
      let regex = new RegExp(words.join(" "), "gi");
      if (d.title?.match(regex)) return true;
      return false;
    }),
  masterGroups: ({ words, data }) =>
    data.filter((d) => {
      let regex = new RegExp(words.join(" "), "gi");
      if (d.title?.match(regex)) return true;
      return false;
    }),
  customGroups: ({ words, data }) =>
    data.filter((d) => {
      let regex = new RegExp(words.join(" "), "gi");
      if (d.title?.match(regex)) return true;
      return false;
    }),
  jobTitles: ({ words, data }) =>
    data.filter((d) => {
      let regex = new RegExp(words.join(" "), "gi");
      if (d.title?.match(regex)) return true;
      return false;
    }),
};
