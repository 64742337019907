// Navigation
const NAVIGATION_LIST = "navigationList";
const NAVIGATION_GRID = "navigationGrid";
const CHAT_NAVIGATION_LIST = "chatnavigationlist";

// News
const NEWSFEED_SINGLE = "newsfeedSingle";
const NEWSFEED_TABS = "newsfeedTabs";
const NEWSFEED_FAQ = "newsfeedFaq";
const NEWSFEED_ARTICLE = "newsfeedArticle";
const NEWS_FILE_ARCHIVE = "newsFileArchive";

const EVENTS = "events";

const EXTERNAL_LINK = "ExternalLink";

const EMPTY_TILE = "empty-tile";

// Registrations
const REGISTRATION = "registration";
const MY_REGISTRATION = "my-registration";
const REGISTRATION_NAVIGATION_LIST = "registration-navigation-list";
const REGISTRATION_FORM = "registration-form";
const REGISTRATION_ACCORDION = "registration-accordion";
const MY_REGISTRATION_ACCORDION = "my-registration-accordion";

const NEWS_MODULES = [NEWSFEED_SINGLE, NEWSFEED_TABS, NEWSFEED_FAQ, NEWSFEED_ARTICLE, NEWS_FILE_ARCHIVE];

// Social
const LOCAL_SOCIAL = "localSocial";
const SOCIAL = "social";
const CHAT = "chat";
const IFRAME = "iframes";
const CUSTOM_GROUP = "custom-group";
const NATIVE_APP = "native-app";

const HIGHSCORE = "highscore";

const REGISTRATION_MODULES = [
  REGISTRATION,
  MY_REGISTRATION,
  REGISTRATION_FORM,
  REGISTRATION_ACCORDION,
  MY_REGISTRATION_ACCORDION,
];

export {
  NAVIGATION_LIST,
  NAVIGATION_GRID,
  NEWSFEED_SINGLE,
  NEWSFEED_TABS,
  EXTERNAL_LINK,
  REGISTRATION,
  REGISTRATION_NAVIGATION_LIST,
  REGISTRATION_FORM,
  REGISTRATION_ACCORDION,
  MY_REGISTRATION,
  MY_REGISTRATION_ACCORDION,
  REGISTRATION_MODULES,
  SOCIAL,
  LOCAL_SOCIAL,
  NEWS_MODULES,
  CHAT,
  IFRAME,
  CUSTOM_GROUP,
  CHAT_NAVIGATION_LIST,
  EVENTS,
  NATIVE_APP,
  EMPTY_TILE,
  HIGHSCORE,
};
