import React from "react";
import { css } from "emotion";
import { CalendarRangeIcon } from "mdi-react";
import PropTypes from "prop-types";
//Style

import common from "../../style/common";
import colors from "../../style/colors";
import { getHoverFromPrimaryColor, getActiveFromPrimaryColor } from "../../utilities/color-modifiers";
import { useSelector } from "react-redux";

/**
 * Component for handling a user picking a date
 * @param {function} onDateChange - A change listener that returns only the new date
 * @param {function} onChange - A regular onchange listener that returns the original event
 * @param {string} defaultDate - A specific date already selected
 * @param {string} name - html-attribute. Used to assicate inputs with label. `name={props.name}`
 */
function DatePicker(props) {
  const { onDateChange, onChange, defaultDate, name = "", style, disabled = false } = props;
  const primaryColor = useSelector((s) => s.appConfig.primaryColor);

  return (
    <div className={`${componentStyle(primaryColor)} ${props.className || ""}`} style={style}>
      <label>
        <CalendarRangeIcon className="svg" />
        <input
          disabled={disabled}
          type="date"
          name={name}
          value={props.value}
          defaultValue={defaultDate}
          min={props.min}
          max={props.max}
          required={props.required}
          onChange={(e) => {
            if (onDateChange) onDateChange(e.target.value);
            if (onChange) onChange(e);
          }}
          data-test-id={props["data-test-id"]}
        />
      </label>
    </div>
  );
}
var height = 40;
const componentStyle = (primaryColor) => css`
  display: flex;
  align-items: end;
  line-height: ${height}px;
  width: 100%;

  label {
    display: flex;
    width: 100%;
    border-radius: 3px;
    border: 1px solid var(--midGrey);
    outline: 0;
    cursor: pointer;

    &:hover {
      background-color: ${getHoverFromPrimaryColor(primaryColor)};
    }

    &:active {
      border-color: ${primaryColor};
      background-color: ${getActiveFromPrimaryColor(primaryColor)};
    }
  }

  &:active {
    svg {
      border-color: ${primaryColor};
    }
  }

  .svg {
    position: relative;
    margin-right: -40px;
    padding: 0.45rem;
    width: 40px;
    color: var(--darkGrey);
    height: ${height}px;
    border-right: 1px solid var(--midGrey);
  }

  input {
    cursor: pointer;
    background: transparent;
    padding-left: 3rem;
    display: block;
    height: ${height}px;
    color: var(--darkGrey);
    width: 100%;
    font-family: ${common.fontStack};
    font-size: 1rem;
    border: 0;
    outline: 0;
    line-height: 2.5rem;
  }
`;
DatePicker.propTypes = {
  /** function to execute when a date is selected/clicked -> Returns only the selected date*/
  onDateChange: PropTypes.func,
  /** function to execute when a date is selected/clicked -> Returns a regular event*/
  onChange: PropTypes.func,
};
export default DatePicker;
