import { css } from "emotion";
import DatePicker from "../../ui/DatePicker";
import DropDown from "../../ui/dropDown/components/DropDown";
import breakpoints from "../../../config/breakpoints";
import TextareaInput from "../../ui/TextareaInput";
import TextInput from "../../ui/TextInput";
import ButtonRounded from "../../ui/ButtonRounded";
import { useDispatch, useSelector } from "react-redux";
import { checkIn, checkOut, updateFormData } from "../../../actions/checkInActions";
import { addToast } from "../../../actions/uiActions";
import styleTypes from "../../../config/styleTypes";

const hours = [
  { id: "00", name: "00" },
  { id: "01", name: "01" },
  { id: "02", name: "02" },
  { id: "03", name: "03" },
  { id: "04", name: "04" },
  { id: "05", name: "05" },
  { id: "06", name: "06" },
  { id: "07", name: "07" },
  { id: "08", name: "08" },
  { id: "09", name: "09" },
  { id: "10", name: "10" },
  { id: "11", name: "11" },
  { id: "12", name: "12" },
  { id: "13", name: "13" },
  { id: "14", name: "14" },
  { id: "15", name: "15" },
  { id: "16", name: "16" },
  { id: "17", name: "17" },
  { id: "18", name: "18" },
  { id: "19", name: "19" },
  { id: "20", name: "20" },
  { id: "21", name: "21" },
  { id: "22", name: "22" },
  { id: "23", name: "23" },
];

const minutes = [
  { id: "00", name: "00" },
  { id: "05", name: "05" },
  { id: "10", name: "10" },
  { id: "15", name: "15" },
  { id: "20", name: "20" },
  { id: "25", name: "25" },
  { id: "30", name: "30" },
  { id: "35", name: "35" },
  { id: "40", name: "40" },
  { id: "45", name: "45" },
  { id: "50", name: "50" },
  { id: "55", name: "55" },
];

function CheckInForm({
  onSubmit,
  submitButtonContent,
  submitButtonStyleType = styleTypes.neutral,
  showCheckoutTime = true,
  selectedUserId,
}) {
  const dispatch = useDispatch();
  const { currentCheckIn, checkInForm } = useSelector((s) => s.checkIn);
  const { formData } = checkInForm; // Destructured for readability in the template :)

  function handleOnChange(e) {
    // Removing this crashes the text input on 2nd input. Not sure why. Leaving it in as
    // it isn't a big deal i guess
    e.persist();

    dispatch(updateFormData({ name: e.target.name, value: e.target.value }));
  }

  function handleSubmitForm(e) {
    e.preventDefault();

    // Validate that company is filled out. This applies both for checkIn and checkOut
    if (!formData.company) {
      return dispatch(
        addToast({
          duration: 7000,
          title: "Du mangler at udfylde noget",
          content: "Du mangler at udfylde 'virksomhed'",
          styleType: "error",
        })
      );
    }

    // In case the form is used for something else, then an onSubmit prop can be submitted. The form will not use its default
    // submit-handling if this is provided
    if (onSubmit) {
      onSubmit();
      return;
    }

    // Now the logic branches off into checkIn and checkOut. Only for checkOut is checkouttimestamp required
    if (currentCheckIn.data) {
      if (!formData.checkOutDate) {
        return dispatch(
          addToast({
            duration: 7000,
            title: "Du mangler at udfylde noget",
            content: "Du mangler at udfylde 'Sluttidspunkt'",
          })
        );
      }

      dispatch(checkOut({ userId: selectedUserId }));
    } else {
      dispatch(checkIn({ userId: selectedUserId }));
    }
  }

  if (currentCheckIn.isLoading) return null; // Der er en loading state i CheckInStatus som vises lige ovenfor formen så derfor skjuler vi den indtil den er klar

  return (
    <form className={componentStyle()} onSubmit={handleSubmitForm}>
      <p className="label">Startidspunkt</p>
      <div className="date-time-line-wrapper">
        <DatePicker
          className="date"
          value={formData.checkInDate}
          name="checkInDate"
          onChange={handleOnChange}
          data-test-id="check-in-form__starting-datepicker"
        />
        <DropDown
          className="dropdown"
          options={hours}
          value={formData.checkInHour}
          name="checkInHour"
          onChange={handleOnChange}
          data-test-id="check-in-form__starting-hour-dropdown"
        />
        <p className="colon-seperator">:</p>
        <DropDown
          className="dropdown"
          options={minutes}
          value={formData.checkInMinute}
          name="checkInMinute"
          onChange={handleOnChange}
          data-test-id="check-in-form__starting-hour-dropdown"
        />
      </div>

      {showCheckoutTime && (
        <>
          <p className="label">Sluttidspunkt</p>
          <div className="date-time-line-wrapper">
            <DatePicker
              className="date"
              value={formData.checkOutDate}
              name="checkOutDate"
              onChange={handleOnChange}
              data-test-id="check-in-form__ending-datepicker"
            />
            <DropDown
              className="dropdown"
              options={hours}
              value={formData.checkOutHour}
              name="checkOutHour"
              onChange={handleOnChange}
              data-test-id="check-in-form__ending-hour-dropdown"
            />
            <p className="colon-seperator">:</p>
            <DropDown
              className="dropdown"
              options={minutes}
              value={formData.checkOutMinute}
              name="checkOutMinute"
              onChange={handleOnChange}
              data-test-id="check-in-form__ending-minute-dropdown"
            />
          </div>
        </>
      )}

      <p className="label">Kommentar</p>
      <TextareaInput
        data-test-id="check-in-form-comment"
        style={{ marginBottom: "2rem" }}
        name="comment"
        value={formData.comment}
        onChange={handleOnChange}
      />

      <p className="label">Virksomhed</p>
      <TextInput
        data-test-id={`check-in-form-company`}
        style={{ marginBottom: "2rem" }}
        name="company"
        value={formData.company}
        onChange={handleOnChange}
      />

      <ButtonRounded
        data-test-id={`check-in-form-button`}
        className="button"
        type="submit"
        active={checkInForm.isSubmitting}
        styleType={submitButtonStyleType}
      >
        {submitButtonContent}
      </ButtonRounded>
    </form>
  );
}

const componentStyle = () => css`
  max-width: ${breakpoints.md}px;
  margin: 0 auto;
  padding: 1rem;

  p.label {
    display: block;
    font-size: 1rem;
    color: var(--black);
    margin: 0 0 0.5rem 0;
  }

  .date-time-line-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    .date {
      margin-right: 0.7rem;
    }

    .colon-seperator {
      margin: 0 0.35rem;
    }

    .dropdown {
      flex-shrink: 0;
      flex-basis: 65px;
    }
  }

  button {
    width: 100%;
  }
`;

export default CheckInForm;
