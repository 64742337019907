import { useDispatch, useSelector } from "react-redux";
import CheckInStatus from "../../../components/CheckInStatus";
import CheckInForm from "../../../components/CheckInForm";
import { useEffect } from "react";
import { setFormToCurrentCheckIn } from "../../../../../actions/checkInActions";
import { LoginIcon, LogoutIcon } from "mdi-react";
import styleTypes from "../../../../../config/styleTypes";

function CheckInPageFormTab({}) {
  const dispatch = useDispatch();
  const currentCheckIn = useSelector((s) => s.checkIn.currentCheckIn);

  useEffect(() => {
    // Set form to current checkIn
    dispatch(setFormToCurrentCheckIn());
  }, [currentCheckIn]);

  return (
    <>
      <CheckInStatus isCheckedIn={currentCheckIn.data ? true : false} />
      <CheckInForm
        submitButtonStyleType={currentCheckIn.data ? styleTypes.error : styleTypes.ok}
        showCheckoutTime={currentCheckIn.data ? true : false}
        submitButtonContent={
          currentCheckIn.data ? (
            <>
              <LogoutIcon style={{ width: "1.1rem", height: "1.1rem", marginRight: "0.5rem" }} />
              Tjek ud
            </>
          ) : (
            <>
              <LoginIcon style={{ width: "1.1rem", height: "1.1rem", marginRight: "0.5rem" }} />
              Tjek ind
            </>
          )
        }
      />
    </>
  );
}

export default CheckInPageFormTab;
