import { combineReducers } from "redux";

// Reducers
import academyReducer from "./academyReducer";
import anniversaryReducer from "./anniversaryReducer";
import appConfigReducer from "./appConfigReducer";
import authReducer from "./authReducer";
import birthdayReducer from "./birthdayReducer";
import checklistReducer from "./checklistReducer";
import checkInReducer from "./checkInReducer";
import contactReducer from "./contactReducer";
import liveChatReducer from "./liveChatReducer";
import equipmentBookingReducer from "./equipmentBookingReducer";
import eventReducer from "./eventReducer";
import languageReducer from "./languageReducer";
import newsReducer from "./newsReducer";
import pagesReducer from "./pagesReducer";
import registrationReducer from "./registrationReducer";
import searchPageReducer from "./searchPageReducer";
import sideMenuReducer from "./sideMenuReducer";
import socialReducer from "./socialReducer";
import targetGroupModalReducer from "../components/ui/TargetGroupModal/reducers/targetGroupModalReducer";
import themeQuizReducer from "./themeQuizReducer";
import uiReducer from "./uiReducer";
import timeRegistrationReducer from "./timeRegistrationReducer";
import pageBuilderReducer from "./pageBuilderReducer";
import pageBuilderFilterReducer from "./pageBuilderFilterReducer";
import notificationReducer from "./notificationReducer";
import mediaUploadReducer from "./mediaUploadReducer";

export default combineReducers({
  academy: academyReducer,
  anniversary: anniversaryReducer,
  appConfig: appConfigReducer,
  auth: authReducer,
  birthday: birthdayReducer,
  checklist: checklistReducer,
  checkIn: checkInReducer,
  contact: contactReducer,
  equipmentBooking: equipmentBookingReducer,
  event: eventReducer,
  language: languageReducer,
  liveChat: liveChatReducer,
  news: newsReducer,
  notification: notificationReducer,
  pages: pagesReducer,
  registration: registrationReducer,
  searchPage: searchPageReducer,
  sideMenu: sideMenuReducer,
  social: socialReducer,
  targetGroupModal: targetGroupModalReducer,
  themeQuiz: themeQuizReducer,
  ui: uiReducer,
  timeRegistration: timeRegistrationReducer,
  pageBuilder: pageBuilderReducer,
  pageBuilderFilter: pageBuilderFilterReducer,
  mediaUpload: mediaUploadReducer,
});
