import {
  HIDE_TARGET_GROUP_PANEL,
  SHOW_TARGET_GROUP_PANEL,
  GET_INITIAL_DATA,
  GET_INITIAL_DATA_SUCCESS,
  SET_SELECTION_PANEL_ACTIVE_TAB_INDEX,
  SET_TARGET_GROUP_PANEL_ACTIVE_TAB_INDEX,
  ADD_TO_TARGET_GROUP,
  REMOVE_FROM_TARGET_GROUP,
  ADD_ALL_TO_TARGET_GROUP,
  RESET_TARGET_GROUP,
  REMOVE_ALL_FROM_TARGET_GROUP,
  SET_INITIAL_TARGET_GROUP,
} from "./targetGroupModalctionsTypes";

import { groupTypes } from "../config/groupTypes";

import req from "../../../../utilities/request-utility";

export function getInitialData(cb) {
  return async function (dispatch, getState) {
    dispatch({ type: GET_INITIAL_DATA });

    const [users, userGroups, masterGroups, customGroups, jobTitles] = await Promise.all([
      req()(`users?limit=10000?includeCustomeGroups=true`),
      req()(`user-groups?includeMasterGroup=1&countMembers=1`),
      req()(`master-groups?countMembers=1`),
      req()(`activity-groups?countMembers=1`),
      req()(`job-titles?countUsers=1`),
    ]);

    dispatch({
      type: GET_INITIAL_DATA_SUCCESS,
      payload: {
        users: users.data,
        userGroups: userGroups.data,
        masterGroups: masterGroups.data,
        customGroups: customGroups.data,
        jobTitles: jobTitles.data,
      },
    });
  };
}

export function setInitialTargetGroup(targetGroup) {
  return {
    type: SET_INITIAL_TARGET_GROUP,
    payload: targetGroup,
  };
}

export function showTargetGroupPanel() {
  return {
    type: SHOW_TARGET_GROUP_PANEL,
  };
}

export function hideTargetGroupPanel() {
  return {
    type: HIDE_TARGET_GROUP_PANEL,
  };
}

/**
 * @param {Number} tabIndex The index to which the tabView and tabBar should be aT
 */
export function setSelectionPanelTabIndex(tabIndex) {
  return {
    type: SET_SELECTION_PANEL_ACTIVE_TAB_INDEX,
    payload: tabIndex,
  };
}

/**
 * @param {Number} tabIndex The index to which the tabView and tabBar should be aT
 */
export function setTargetGroupPanelTabIndex(tabIndex) {
  return {
    type: SET_TARGET_GROUP_PANEL_ACTIVE_TAB_INDEX,
    payload: tabIndex,
  };
}

/********** Add all functions  **********/
export function addAllUsersToTargetGroup(ids) {
  return async function (dispatch, getState) {
    let targetGroupUsers = getState().targetGroupModal.targetGroup.users;

    /* Remove already added users */
    let usersToAdd = ids.filter((user) => {
      if (targetGroupUsers.includes(user)) {
        return false;
      } else {
        return true;
      }
    });

    dispatch({
      type: ADD_ALL_TO_TARGET_GROUP,
      payload: {
        ids: usersToAdd,
        type: groupTypes.users,
      },
    });
  };
}
export function addAllUserGroupsToTargetGroup(ids) {
  return async function (dispatch, getState) {
    let targetGroupUserGroups = getState().targetGroupModal.targetGroup.userGroups;

    /* Remove already added users */
    let idsToAdd = ids.filter((id) => {
      if (targetGroupUserGroups.includes(id)) {
        return false;
      } else {
        return true;
      }
    });

    dispatch({
      type: ADD_ALL_TO_TARGET_GROUP,
      payload: {
        ids: idsToAdd,
        type: groupTypes.userGroups,
      },
    });
  };
}
export function addAllMasterGroupsToTargetGroup(ids) {
  return async function (dispatch, getState) {
    let targetGroupMasterGroups = getState().targetGroupModal.targetGroup.masterGroups;

    /* Remove already added users */
    let idsToAdd = ids.filter((id) => {
      if (targetGroupMasterGroups.includes(id)) {
        return false;
      } else {
        return true;
      }
    });

    dispatch({
      type: ADD_ALL_TO_TARGET_GROUP,
      payload: {
        ids: idsToAdd,
        type: groupTypes.masterGroups,
      },
    });
  };
}
export function addAllCustomGroupsToTargetGroup(ids) {
  return async function (dispatch, getState) {
    let targetGroupCustomGroups = getState().targetGroupModal.targetGroup.customGroups;

    /* Remove already added customs */
    let idsToAdd = ids.filter((id) => {
      if (targetGroupCustomGroups.includes(id)) {
        return false;
      } else {
        return true;
      }
    });

    dispatch({
      type: ADD_ALL_TO_TARGET_GROUP,
      payload: {
        ids: idsToAdd,
        type: groupTypes.customGroups,
      },
    });
  };
}
export function addAllJobTitlesToTargetGroup(titles) {
  return async function (dispatch, getState) {
    let targetGroupJobTitles = getState().targetGroupModal.targetGroup.jobTitles;

    /* Remove already added customs */
    let titlesToAdd = titles.filter((title) => {
      if (targetGroupJobTitles.includes(title)) {
        return false;
      } else {
        return true;
      }
    });

    dispatch({
      type: ADD_ALL_TO_TARGET_GROUP,
      payload: {
        ids: titlesToAdd,
        type: groupTypes.jobTitles,
      },
    });
  };
}

/********** Remove all functions  **********/
export function removeAllUsersFromTargetGroup() {
  return {
    type: REMOVE_ALL_FROM_TARGET_GROUP,
    payload: groupTypes.users,
  };
}
export function removeAllUserGroupsFromTargetGroup() {
  return {
    type: REMOVE_ALL_FROM_TARGET_GROUP,
    payload: groupTypes.userGroups,
  };
}
export function removeAllMasterGroupsFromTargetGroup() {
  return {
    type: REMOVE_ALL_FROM_TARGET_GROUP,
    payload: groupTypes.masterGroups,
  };
}
export function removeAllCustomGroupsFromTargetGroup() {
  return {
    type: REMOVE_ALL_FROM_TARGET_GROUP,
    payload: groupTypes.customGroups,
  };
}
export function removeAllJobTitlesFromTargetGroup() {
  return {
    type: REMOVE_ALL_FROM_TARGET_GROUP,
    payload: groupTypes.jobTitles,
  };
}

/********** Add one entity  **********/
export function addUserToTargetGroup(userId) {
  return {
    type: ADD_TO_TARGET_GROUP,
    payload: {
      id: userId,
      type: groupTypes.users,
    },
  };
}
export function addUserGroupToTargetGroup(id) {
  return {
    type: ADD_TO_TARGET_GROUP,
    payload: {
      id: id,
      type: groupTypes.userGroups,
    },
  };
}
export function addMasterGroupToTargetGroup(id) {
  return {
    type: ADD_TO_TARGET_GROUP,
    payload: {
      id: id,
      type: groupTypes.masterGroups,
    },
  };
}
export function addCustomGroupToTargetGroup(id) {
  return {
    type: ADD_TO_TARGET_GROUP,
    payload: {
      id: id,
      type: groupTypes.customGroups,
    },
  };
}
export function addJobTitleToTargetGroup(title) {
  return {
    type: ADD_TO_TARGET_GROUP,
    payload: {
      id: title,
      type: groupTypes.jobTitles,
    },
  };
}

/********** Remove one entity  **********/
export function removeUserFromTargetGroup(userId) {
  return {
    type: REMOVE_FROM_TARGET_GROUP,
    payload: {
      id: userId,
      type: groupTypes.users,
    },
  };
}
export function removeUserGroupFromTargetGroup(id) {
  return {
    type: REMOVE_FROM_TARGET_GROUP,
    payload: {
      id: id,
      type: groupTypes.userGroups,
    },
  };
}
export function removeMasterGroupFromTargetGroup(id) {
  return {
    type: REMOVE_FROM_TARGET_GROUP,
    payload: {
      id: id,
      type: groupTypes.masterGroups,
    },
  };
}
export function removeCustomGroupFromTargetGroup(id) {
  return {
    type: REMOVE_FROM_TARGET_GROUP,
    payload: {
      id: id,
      type: groupTypes.customGroups,
    },
  };
}
export function removeJobTitleFromTargetGroup(id) {
  return {
    type: REMOVE_FROM_TARGET_GROUP,
    payload: {
      id: id,
      type: groupTypes.jobTitles,
    },
  };
}

export function resetTargetGroup() {
  return {
    type: RESET_TARGET_GROUP,
  };
}
