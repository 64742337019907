import store from "../store";

/** Checks if a given userObject is admin. If no user is provided it will default to the user in redux auth state (currently signed in user)
 * @param {object} anon
 * @param {object} anon.user - optional
 */
export default function isUserAdmin({ user } = {}) {
  if (!user) user = store.getState().auth.user;

  if (user.adminId && user.admin.id) {
    return true;
  } else {
    return false;
  }
}
